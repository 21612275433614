import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import Home from './components/Home';
import ExitScreen from './components/ExitScreen';
require('typeface-roboto')

function App() {
  return (
    <div className="App">
      <ExitScreen />
    </div>
  );
}

export default App;
