import React from 'react';
import {isMobile} from "react-device-detect";
import Blob1 from '../img/blob1.svg';
import Blob2 from '../img/blob2.svg';
import './css/Home.css';

const ExitScreen = () => (
    <div className="exit-screen">
        <div className="background"></div>
        <div className="logo">NY Unemployment Info</div>
        <div className="call">            
            <img src={Blob1} className="top"/>
            <img src={Blob2} className="bottom-left"/>
        </div>
        <div className="message-container">
          <h2>We have ceased operations</h2>
          <p>Due to decreasing call volume, the NY Unemployment Info Helpline is no longer operating.</p>
          <p>We appreciate the opportunity to serve you in your time of need. Stay safe, and we wish you the best.</p>
        </div>
        <div className="resources">
          <h3>Helpful Resources</h3>
          <a href="https://www.labor.ny.gov/ui/claimantinfo/Claimant%20Handbook%20-%20Languages.shtm">NY Unemployment Insurance Claimant Handbook (multiple languages)</a>
          <a href="https://labor.ny.gov/ui/pdfs/ui-covid-faq.pdf">COVID Unemployment FAQs</a>
          <a href="https://unemployment.labor.ny.gov/login">NY Unemployment Benefits Portal</a>
        </div>
        <div className="footer">
            <p>Keep yourself and others safe. Stay inside if you can, stay apart if you must leave the house, and wash your hands more frequently.</p>
        </div>
    </div>
)

export default ExitScreen;